import React from 'react';
import Layout from '../components/Layout';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import { attractions } from '../assets/attractions';
import { useTranslation } from 'react-i18next';
import 'pure-react-carousel/dist/react-carousel.es.css';
const Attractions = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          extension: { regex: "/webp/" }
          absolutePath: { regex: "/src/images/attractions/" }
        }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FIXED, height: 450, width: 450)
            }
          }
        }
      }
      mobile: allFile(
        filter: {
          extension: { regex: "/webp/" }
          absolutePath: { regex: "/src/images/attractions/" }
        }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
      }
    }
  `);
  const checkWindow = () => {
    return typeof window !== 'undefined' && window.innerWidth > 700;
  };
  const checkSmallDevices = () => {
    const returnImage = (images, isMobile) => {
      return images.edges.map((edge, key) => (
        <React.Fragment key={key}>
          <Slide index={key}>
            {isMobile ? (
              <GatsbyImage
                image={edge.node.childImageSharp.gatsbyImageData}
                fadeIn={true}
                durationFadeIn={1000}
              />
            ) : (
              <GatsbyImage
                image={edge.node.childImageSharp.gatsbyImageData}
                fadeIn={true}
                durationFadeIn={1000}
              />
            )}
          </Slide>
        </React.Fragment>
      ));
    };

    if (checkWindow()) {
      return returnImage(data.images, false);
    } else {
      return returnImage(data.mobile, true);
    }
  };
  const { t } = useTranslation();
  return (
    <Layout fullMenu={true}>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{t('attractionHeader')}</h2>
            <p>{t('attractionDescription')}</p>
          </div>
        </header>
        <div className="wrapper">
          <div className="inner">
            <section className="features">
              <div className="content">
                <p>{t('attractionContent')}</p>
              </div>
            </section>
            <div className="attractions">
              {attractions.map((label, key) => {
                return (
                  <React.Fragment key={key}>
                    <span className="attraction">
                      <i
                        className="icon-check fa fa-check-circle"
                        aria-hidden="true"
                      ></i>
                      <p className="text">
                        {t(`attractionOption.${key}.type`)}
                      </p>
                    </span>
                  </React.Fragment>
                );
              })}
            </div>
            <div
              className="carousel-container"
              style={{ padding: '0rem 0rem 3rem' }}
            >
              <CarouselProvider
                naturalSlideWidth={10}
                naturalSlideHeight={10}
                totalSlides={7}
                autoPlay={true}
                interval={6000}
                visibleSlides={checkWindow() ? 2 : 1}
              >
                <Slider>{checkSmallDevices()}</Slider>
              </CarouselProvider>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Attractions;
