export const attractions = [
  {
    type: 'Latarnia Morska w Niechorzu',
  },
  {
    type: 'Park miniatur Latarni morskich w Niechorzu',
  },
  {
    type: 'Kolejka wąskotorowa',
  },
  {
    type: 'Motylarnia w Niechorzu',
  },
  {
    type: 'Muzeum rybołóstwa w Niechorzu',
  },
  {
    type: 'Park wieloryba w Rewalu',
  },
  {
    type: 'Bajkowa chata Rewal',
  },
  {
    type: 'Park Linowy Rewal',
  },
];
